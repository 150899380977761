<template>
  <Footer :columns="columns" />
</template>

<script>
import Footer from 'chimera/all/themes/blueflow/components/layout/Footer'

export default {
  components: {
    Footer
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      columns: [
        {
          title: 'Over ons',
          links: [
            {
              text: 'Contact',
              to: '/contact-opnemen'
            }
          ]
        },
        {
          title: 'Professionals',
          links: [
            {
              text: 'Aanmelden als pro',
              to: '/contact-opnemen-bedrijf'
            }
          ]
        },
        {
          title: 'Consumenten',
          links: [
            {
              text: 'Informatie over accountants',
              to: '/informatie-over-accountants'
            }
          ]
        }
      ]
    }
  }
}
</script>
